<template>
  <!-- 主要內容 -->
  <section class="container pb-8 ">
    <div class="row">
      <div class="col-12 col-md-8 mx-auto">
        <h2 class="font-weight-bold mb-3 mb-md-6">會員修改密碼</h2>
        <el-form
          style="width: 100%;"
          :model="MemberForm"
          label-position="top"
          :rules="MemberRules"
          ref="MemberForm"
          class="cs-form"
          data-style="2"
          @submit.prevent.native
        >
          <!-- 密碼 -->
          <div class="form-group row ">
            <label class="col-12 col-md-3 col-form-label necessary" for="memberPassword">
              舊密碼
            </label>
            <el-form-item prop="memberPassword" class="col-12 col-md-9 mb-2">
              <el-input
                :class="`el-input__input`"
                placeholder="舊密碼"
                type="password"
                id="memberPassword"
                v-model="MemberForm.memberPassword"
              />
            </el-form-item>
          </div>
          <div class="form-group row ">
            <label class="col-12 col-md-3 col-form-label necessary" for="memberNewPassword">
              新密碼
            </label>
            <el-form-item prop="memberNewPassword" class="col-12 col-md-9 mb-2">
              <el-input
                :class="`el-input__input`"
                placeholder="新密碼"
                type="password"
                id="memberNewPassword"
                v-model="MemberForm.memberNewPassword"
              />
            </el-form-item>
          </div>
          <div class="form-group row ">
            <label class="col-12 col-md-3 col-form-label necessary" for="memberConfirmPassword">
              確認密碼
            </label>
            <el-form-item prop="memberConfirmPassword" class="col-12 col-md-9 mb-2">
              <el-input
                :class="`el-input__input`"
                placeholder="確認密碼"
                type="password"
                id="memberConfirmPassword"
                v-model="MemberForm.memberConfirmPassword"
              />
            </el-form-item>
          </div>
          <!-- 送出按紐 -->
          <div class="form-group row mb-6 mb-md-8">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-hover-primary text-white btn-lg border-radius-l w-100"
                @click="ModifyMemberPassword"
              >
                <span class="small">儲存</span>
              </button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </section>
</template>

<script>
import { ModifyMemberPassword } from '@/api/MemberApi';

export default {
  data() {
    // 建立你的初始化 model data
    return {
      MemberForm: {
        // model data
        memberPassword: '',
        memberNewPassword: '',
        memberConfirmPassword: '',
      },
      MemberRules: {
        // 檢查輸入的值規則
        memberPassword: [{ required: true, message: '請輸入舊密碼', trigger: 'blur' }],
        memberNewPassword: [
          { required: true, message: '請輸入新密碼', trigger: 'blur' },
          { min: 6, max: 20, message: '長度請輸入 6 到 20 個字' },
          {
            pattern: /^([a-zA-Z]+\d+|\d+[a-zA-Z]+)[a-zA-Z0-9]*$/,
            message: '請輸入英數字',
            trigger: 'blur',
          },
        ],
        memberConfirmPassword: [
          { required: true, message: '請輸入確認密碼', trigger: 'blur' },
          { min: 6, max: 20, message: '長度請輸入 6 到 20 個字' },
          {
            pattern: /^([a-zA-Z]+\d+|\d+[a-zA-Z]+)[a-zA-Z0-9]*$/,
            message: '請輸入英數字',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    // 一開始執行的地方
  },
  methods: {
    // 你的 js function
    ModifyMemberPassword() {
      // 儲存按鈕
      this.$refs.MemberForm.validate((valid) => {
        if (valid) {
          ModifyMemberPassword(this.MemberForm).then((response) => {
            if (response.data.statusCode === 0) {
              this.$message({
                showClose: true,
                type: 'success',
                message: '修改成功，下次登入請使用新密碼進行登入',
              });
              this.MemberFormmemberPassword = '';
              this.MemberFormmemberNewPassword = '';
              this.MemberFormmemberConfirmPassword = '';
            } else {
              this.GetCaptchaCode();
              this.$message({
                showClose: true,
                type: 'error',
                message: response.data.message,
              });
            }
          });
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '請將資料填寫完整',
          });
        }
      });
    },
  },
};
</script>

<style></style>
